h1 {
  font-size: 60px;
}

h2 {
  font-size: 45px;
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 25px;
}

li {
  margin-left: 10px;
  list-style-type: disc;
}

p {
  margin-top: 2%;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 30px
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 22px
  }

  h5 {
    font-size: 20px;
  }

  p {
    margin-top: 10%;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px
  }

  h5 {
    font-size: 16px;
  }

  p {
    margin-top: 10%;
  }
}